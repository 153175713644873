import React, { useContext, useEffect } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";

import "../ProductDetails/ProductDetails.css";

// import cookies from "js-cookie";
import { useTranslation } from "react-i18next";

import Footer from "../../components/Footer/Footer";
import HeroImg from "../../assets/img/hero-img.png";
import AliLogo from "../../assets/img/ali-logo.png";
import ProductSkeleton from "../../components/SkeletonLoader/ProductSkeleton";
import {
  Capitalize,
  numberWithCommas,
} from "../../components/helpers/functionHelpers";
import { useEventTag } from "../../components/hooks/useEventTag";
import { GlobalContext } from "../../components/utils/GlobalState";
import { useGetAliexpressProductsByCategoryName } from "../../components/hooks/useGetAliexpressProductsByCategoryName";
import NewHeader from "../../components/NewHeader/NewHeader";

const Alicategory = () => {
  const { t } = useTranslation();
  const { user } = useContext(GlobalContext);
  const { feedName } = useParams();
  const decodedCategory = decodeURIComponent(feedName);
  const navigate = useNavigate();
  // const selectedCurrency = cookies.get("currency") || "NGN";
  const { handleEventTag } = useEventTag();

  const ref = React.useRef(null);

  const ref1 = React.useRef(null);

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  const scroll2 = (scrollOffset) => {
    ref1.current.scrollLeft += scrollOffset;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  const handleEventCategory = (categoryName) => {
    if (user) {
      handleEventTag(`c_${categoryName}`, user.id);
    } else {
      handleEventTag(`c_${categoryName}`);
    }
  };

  const handleEventAction = (productName) => {
    if (user) {
      handleEventTag(`p_${productName}`, user.id);
    } else {
      handleEventTag(`p_${productName}`);
    }
  };

  const { products, loading, error } =
    useGetAliexpressProductsByCategoryName(decodedCategory);

  if (error) return null;

  return (
    <>
      <NewHeader />
      <section id="product-listing">
        <div className="container">
          <div className="d-flex justify-content-end mb-3">
            <i
              className="fas fa-chevron-left me-4  chev-left carousel-nav"
              onClick={() => scroll(-275)}
            ></i>
            <i
              className="fas fa-chevron-right chev-right carousel-nav"
              onClick={() => scroll(275)}
            ></i>
          </div>

          <ul className="product-cat-list" ref={ref}>
            <NavLink
              to={`/aliexpress/category/${encodeURIComponent("NG Hair 7.23")}`}
              className={({ isActive }) => {
                return isActive ? "active-product" : "product-cat";
              }}
              onClick={() => handleEventCategory("hairs")}
            >
              <li>Hairs & Wigs</li>
            </NavLink>
            <NavLink
              to={`/aliexpress/category/${encodeURIComponent(
                "DS_HairRemoval&Lights&Tools 10$+"
              )}`}
              className={({ isActive }) => {
                return isActive ? "active-product" : "product-cat";
              }}
              onClick={() => handleEventCategory("hairs")}
            >
              <li>Hair Removal & Tools</li>
            </NavLink>
            <NavLink
              to={`/aliexpress/category/${encodeURIComponent(
                "AEB_Clothing_NG"
              )}`}
              className={({ isActive }) => {
                return isActive ? "active-product" : "product-cat";
              }}
            >
              <li>Fashions</li>
            </NavLink>
            <NavLink
              to={`/aliexpress/category/${encodeURIComponent(
                "NG watches 719"
              )}`}
              className={({ isActive }) => {
                return isActive ? "active-product" : "product-cat";
              }}
            >
              <li>Wrist Watches</li>
            </NavLink>
            <NavLink
              to={`/aliexpress/category/${encodeURIComponent(
                "NG home appliances"
              )}`}
              className={({ isActive }) => {
                return isActive ? "active-product" : "product-cat";
              }}
            >
              <li>Home Appliances</li>
            </NavLink>
            <NavLink
              to={`/aliexpress/category/${encodeURIComponent(
                "NG electronic 7.19"
              )}`}
              className={({ isActive }) => {
                return isActive ? "active-product" : "product-cat";
              }}
            >
              <li>Electronics</li>
            </NavLink>
            <NavLink
              to={`/aliexpress/category/${encodeURIComponent(
                "AEB_Phone&OfficeSupplies_NG"
              )}`}
              className={({ isActive }) => {
                return isActive ? "active-product" : "product-cat";
              }}
            >
              <li>Phone & Office Supplies</li>
            </NavLink>
            <NavLink
              to={`/aliexpress/category/${encodeURIComponent(
                "DS_Fashions&Cosmetics 20$+"
              )}`}
              className={({ isActive }) => {
                return isActive ? "active-product" : "product-cat";
              }}
            >
              <li>Cosmetics</li>
            </NavLink>
            <NavLink
              to={`/aliexpress/category/${encodeURIComponent(
                "AEB_BeautySupplies_NG"
              )}`}
              className={({ isActive }) => {
                return isActive ? "active-product" : "product-cat";
              }}
            >
              <li>Beauty Supplies</li>
            </NavLink>
            <NavLink
              to={`/aliexpress/category/${encodeURIComponent(
                "phones&accessories_ZA topsellers_ 20240423"
              )}`}
              className={({ isActive }) => {
                return isActive ? "active-product" : "product-cat";
              }}
            >
              <li>Phones & Accessories</li>
            </NavLink>
            <NavLink
              to={`/aliexpress/category/${encodeURIComponent(
                "AEB_ ComputerAccessories_EG"
              )}`}
              className={({ isActive }) => {
                return isActive ? "active-product" : "product-cat";
              }}
            >
              <li>Computer & Accessories</li>
            </NavLink>
            <NavLink
              to={`/aliexpress/category/${encodeURIComponent(
                "AEB_Home&Kitchen_NG"
              )}`}
              className={({ isActive }) => {
                return isActive ? "active-product" : "product-cat";
              }}
              onClick={() => handleEventCategory("hairs")}
            >
              <li>Home & Kitchen</li>
            </NavLink>
            <NavLink
              to={`/aliexpress/category/${encodeURIComponent(
                "USA_beauty&health_topsellers"
              )}`}
              className={({ isActive }) => {
                return isActive ? "active-product" : "product-cat";
              }}
              onClick={() => handleEventCategory("hairs")}
            >
              <li>Health Supplies</li>
            </NavLink>
            <NavLink
              to={`/aliexpress/category/${encodeURIComponent(
                "DS_Jewelry&Watch 10$+"
              )}`}
              className={({ isActive }) => {
                return isActive ? "active-product" : "product-cat";
              }}
              onClick={() => handleEventCategory("hairs")}
            >
              <li>Jewelries</li>
            </NavLink>
            <NavLink
              to={`/aliexpress/category/${encodeURIComponent(
                "DS_Sports-Clothing&Shoes"
              )}`}
              className={({ isActive }) => {
                return isActive ? "active-product" : "product-cat";
              }}
              onClick={() => handleEventCategory("hairs")}
            >
              <li>Sports</li>
            </NavLink>
            <NavLink
              to={`/aliexpress/category/${encodeURIComponent(
                "DS_Automobile&Accessories_bestsellers"
              )}`}
              className={({ isActive }) => {
                return isActive ? "active-product" : "product-cat";
              }}
              onClick={() => handleEventCategory("hairs")}
            >
              <li>Automobile & Accessories</li>
            </NavLink>
            <NavLink
              to={`/aliexpress/category/${encodeURIComponent(
                "DS_Automotive&Motorcycle 10$+"
              )}`}
              className={({ isActive }) => {
                return isActive ? "active-product" : "product-cat";
              }}
              onClick={() => handleEventCategory("hairs")}
            >
              <li>Automotive & Motorcycle</li>
            </NavLink>
            <NavLink
              to={`/aliexpress/category/${encodeURIComponent(
                "DS_DentalCare_stores"
              )}`}
              className={({ isActive }) => {
                return isActive ? "active-product" : "product-cat";
              }}
              onClick={() => handleEventCategory("hairs")}
            >
              <li>DentalCare Stores</li>
            </NavLink>
            <NavLink
              to={`/aliexpress/category/${encodeURIComponent(
                "DS_Mother&Kids 10$+"
              )}`}
              className={({ isActive }) => {
                return isActive ? "active-product" : "product-cat";
              }}
              onClick={() => handleEventCategory("hairs")}
            >
              <li>Mother & Kids</li>
            </NavLink>
            <NavLink
              to={`/aliexpress/category/${encodeURIComponent(
                "DS_Medical Devices_limited stores"
              )}`}
              className={({ isActive }) => {
                return isActive ? "active-product" : "product-cat";
              }}
              onClick={() => handleEventCategory("hairs")}
            >
              <li>Others</li>
            </NavLink>
          </ul>

          {loading ? (
            <ProductSkeleton />
          ) : (
            <>
              <div className="section-head">
                <h2>{t("All_Products")}</h2>
                <div className="mobile-carousel-nav">
                  <i
                    className="fas fa-chevron-left me-4 carousel-nav"
                    onClick={() => scroll2(-275)}
                  ></i>
                  <i
                    className="fas fa-chevron-right carousel-nav"
                    onClick={() => scroll2(275)}
                  ></i>
                </div>
              </div>

              {products?.length > 0 ? (
                <div className="product-wrap" ref={ref1}>
                  {products?.map((product) => (
                    <Link
                      to={`/aliexpress/product-info/${product?.product_id}`}
                      key={product?.product_id}
                      onClick={() => handleEventAction(product?.product_title)}
                    >
                      <div className="product-card">
                        <div className="product-card-img position__relative">
                          <div className="aliexpress-ctn">
                            <img src={AliLogo} alt="aliexpress logo" />
                          </div>
                          <img
                            src={product?.product_main_image_url}
                            className=""
                            alt="..."
                          />
                        </div>
                        <div className="product-card-info">
                          <h3 className="seller-name">
                            {product &&
                              Capitalize(
                                product?.second_level_category_name
                              )}{" "}
                          </h3>
                          {product && product?.product_title.length > 20 ? (
                            <h2 className={"product-name"}>
                              {Capitalize(product?.product_title).slice(0, 20)}
                              ...
                            </h2>
                          ) : (
                            <h2 className={"product-name"}>
                              {Capitalize(product.product_title)}
                            </h2>
                          )}
                          <p className="product-price">
                            <span className="p-currency">
                              {product?.target_original_price_currency}
                            </span>{" "}
                            {product?.target_original_price &&
                              numberWithCommas(
                                product?.target_original_price
                              )}{" "}
                          </p>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              ) : (
                <div className="empty-state">
                  <h6>{t("No_results_found")}</h6>
                  <p>{t("No_product_matched")}</p>
                </div>
              )}
            </>
          )}
        </div>
      </section>
      {/* Banner Area */}
      <section className="banner-area">
        <div className="container">
          <div className="banner-wrap">
            <img src={HeroImg} className="d-block w-100" alt="..." />
          </div>
        </div>
      </section>
      {/* End of Banner Area */}

      <Footer />
    </>
  );
};

export default Alicategory;
