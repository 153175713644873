import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./Home.css";
import "../AllRfq/AllRfq.css";

import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import "react-loading-skeleton/dist/skeleton.css";

import HeroImg2 from "../../assets/img/imagee.png";
import Footer from "../../components/Footer/Footer";
import HeroImg from "../../assets/img/hero-img.png";
import MultipleRfqModal from "../AllRfq/MultipleRfqModal";
import SpotIcon from "../../assets/img/round-spot-icon.svg";
import NewHeader from "../../components/NewHeader/NewHeader";
import DollsAndToys from "../../components/Aliexpress/DollsAndToys";
import RfqSkeleton from "../../components/SkeletonLoader/RfqSkeleton";
import FetchedProducts from "../../components/FetchedProducts/FetchedProducts";
import CategorySkeleton from "../../components/SkeletonLoader/CategorySkeleton";
import ProductSkeleton from "../../components/SkeletonLoader/ProductSkeleton";
import PopularProducts from "../../components/FetchedProducts/PopularProducts";

import { useGetAllRfqs } from "../../components/hooks/useGetAllRfqs";
import { useGetRfqById } from "../../components/hooks/useGetRfqById";
import { convertDateFormat } from "../../components/helpers/convertDate";
import { useGetAllProduct } from "../../components/hooks/useGetAllProduct";
import { useGetAllCategories } from "../../components/hooks/useGetAllCategories";
import {
  numberWithCommas,
  Capitalize,
} from "../../components/helpers/functionHelpers";
import { convertPrice } from "../../components/helpers/convertPrice";
import { useEventTag } from "../../components/hooks/useEventTag";
import { GlobalContext } from "../../components/utils/GlobalState";
import { CurrencyExchangeContext } from "../../components/utils/CurrencyExchangeState";
import { aliexpressCategories, homeImageSlider } from "../../constants";

const Home = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { user } = useContext(GlobalContext);
  const { convertCurrency } = useContext(CurrencyExchangeContext);
  const { loading, allProducts } = useGetAllProduct();
  const { allCategories, cateLoading } = useGetAllCategories();
  const { allRfq, rfqLoading } = useGetAllRfqs();
  const { rfqInfoLoad, rfqInfo, viewRfqInfo } = useGetRfqById();
  const selectedCurrency = cookies.get("currency") || "NGN";
  const { t } = useTranslation();
  const { handleEventTag } = useEventTag();

  const navigate = useNavigate();

  const ref = React.useRef(null);

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  const handleEventCategory = (categoryName) => {
    if (user) {
      handleEventTag(`c_${categoryName}`, user.id);
    } else {
      handleEventTag(`c_${categoryName}`);
    }
  };

  const handleEventAction = (productName, productId) => {
    if (user) {
      handleEventTag(`p_${productName}`, user.id, productId);
    } else {
      handleEventTag(`p_${productName}`, null, productId);
    }
  };

  const handleEventActionRfq = (productName) => {
    if (user) {
      handleEventTag(`r_${productName}`, user.id);
    } else {
      handleEventTag(`r_${productName}`);
    }
  };

  const featuredProductTitle = `${t("Featured")} ${t("Products")}`;
  const popularProductTitle = `${t("Popular")} ${t("Products")}`;

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % homeImageSlider.length);
    }, 6000);

    return () => clearInterval(slideInterval);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <>
      {/* {!clickSearch ? ( */}
      <>
        <NewHeader />

        {/* Home Hero Section */}
        <section id="main-hero-section">
          <div className="container">
            <div className="carousel__wrapper">
              {homeImageSlider.map((image, index) => (
                <div
                  className={`carousel__content ${
                    index === currentSlide ? "active" : "disabled"
                  }`}
                  key={index}
                >
                  <img src={image.src} alt={`Slide ${index + 1}`} />
                </div>
              ))}
              <div className=".buttons-wrapper">
                {homeImageSlider.map((_, index) => (
                  <span
                    key={index}
                    onClick={() => setCurrentSlide(index)}
                    className={` ${
                      index === currentSlide ? "active" : "disabled"
                    }`}
                  />
                ))}
              </div>
            </div>
          </div>
          {/* <div className="container">
            <div
              id="carouselExampleSlidesOnly"
              className="carousel slide carousel-fade"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={HeroImg2} className="d-block w-100" alt="..." />
                </div>
                <div className="carousel-item">
                  <img src={HeroImg2} className="d-block w-100" alt="..." />
                </div>
                <div className="carousel-item">
                  <img src={HeroImg2} className="d-block w-100" alt="..." />
                </div>
              </div>
            </div>
          </div> */}
        </section>
        {/* End of Home Hero Section */}

        {/* Popular Categories */}
        <section id="popular-cat">
          <div className="container">
            <div className="section-head">
              <h2>{t("Popular_Categories")}</h2>
            </div>
            {cateLoading ? (
              <CategorySkeleton />
            ) : (
              <div className="cat-wrap">
                {aliexpressCategories?.map((category, index) => (
                  <Link
                    to={`/aliexpress/category/${encodeURIComponent(
                      category.link
                    )}`}
                    key={index}
                    onClick={() => handleEventCategory(category?.category)}
                  >
                    <div className="cat-box">
                      <img
                        src={category.imgUrl}
                        className=""
                        alt={category?.category}
                      />
                    </div>
                  </Link>
                ))}
                {allCategories?.slice(0, 10).map((category) => (
                  <Link
                    to={`/category/${category.id}`}
                    key={category.id}
                    onClick={() => handleEventCategory(category.category)}
                  >
                    <div className="cat-box">
                      <img src={category.image} className="" alt="..." />
                      {/* <h3>{category.category}</h3> */}
                    </div>
                  </Link>
                ))}
              </div>
            )}
          </div>
        </section>

        {/* Featured Products */}
        <section id="featured-products">
          <div className="container">
            <DollsAndToys
              sectionTitle="Hair & Wigs"
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="NG Hair 7.23"
            />
            <DollsAndToys
              sectionTitle="Fashions"
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="AEB_Clothing_NG"
            />
            <DollsAndToys
              sectionTitle="Wrist Watches"
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="NG watches 719"
            />
            <DollsAndToys
              sectionTitle="Home Appliances"
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="NG home appliances"
            />
            <DollsAndToys
              sectionTitle="Electronics"
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="NG electronic 7.19"
            />
            <FetchedProducts
              sectionTitle={featuredProductTitle}
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
            />

            <DollsAndToys
              sectionTitle="Phone & Office Supplies"
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="AEB_Phone&OfficeSupplies_NG"
            />
            {/* <DollsAndToys
              sectionTitle="Fashions & Cosmetics"
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="DS_Fashions&Cosmetics 20$+"
            /> */}
            <DollsAndToys
              sectionTitle="Beauty Supplies"
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="AEB_BeautySupplies_NG"
            />
            <DollsAndToys
              sectionTitle="Phones &  Accessories"
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="phones&accessories_ZA topsellers_ 20240423"
            />
            {/* <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="USA_beauty&health_topsellers"
            /> */}

            {/* <DollsAndToys
              sectionTitle="Fashions & Cosmetics"
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="DS_Fashions&Cosmetics 20$+"
            />

            

            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="USA_beauty&health_topsellers"
            />

           

         
            <DollsAndToys
              sectionTitle="Computer & Accessories"
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="AEB_ ComputerAccessories_EG"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="computer&office_ZA topsellers_ 20240423"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="consumer electronics_ZA topsellers_ 20240423"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="DS_ConsumerElectronics_bestsellers"
            />

         

            <DollsAndToys
              sectionTitle="Summer Products"
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="AEB_ SummerProducts_EG"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="Business Winning Products with Exclusive Price"
            /> */}
            <PopularProducts
              sectionTitle={popularProductTitle}
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
            />
            <DollsAndToys
              sectionTitle="Home & Kitchen"
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="AEB_Home&Kitchen_NG"
            />
          </div>
        </section>
        {/* End of Featured Products */}

        {/* Banner Area */}
        <section className="banner-area">
          <div className="container">
            <div className="banner-wrap">
              <img src={HeroImg} className="d-block w-100" alt="..." />
            </div>
          </div>
        </section>
        {/* End of Banner Area */}

        <section className="pt-5">
          {/* <div className="container">
            <DollsAndToys
              sectionTitle="Top Sellers"
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="DS_Global_topsellers"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="Business Winning Products with Exclusive Price"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="DS_ SaudiArabia_Clothing&BeautyMachines_100$-"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="DS_Australia_fastdelivery 20231123"
            />
            <DollsAndToys
              sectionTitle="Jewelry & Watch"
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="DS_Jewelry&Watch 10$+"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="DS_Beauty_bestsellers"
            />
            <DollsAndToys
              sectionTitle="Sports Clothing & Shoes"
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="DS_Sports-Clothing&Shoes"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="DS_Sports&Outdoors_bestsellers"
            />
            <DollsAndToys
              sectionTitle="Computer & Office"
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="computer&office_ZA topsellers_ 20240423"
            />
            <DollsAndToys
              sectionTitle=""
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="US 3PL-Warehouse 20240202"
            />
            <DollsAndToys
              sectionTitle="Automobile & Accessories"
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="DS_Automobile&Accessories_bestsellers"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="DS_Automotive&Motorcycle 10$+"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="DS_Automotive&Motorcycle&Parts_limited stores"
            />
          </div> */}
        </section>

        {/* <MoreCollections /> */}

        {/* RFQ Section */}
        <section id="rfq-list">
          <div className="container">
            <div className="section-head">
              <h2>{t("Latest_Request_for_quote")}</h2>
            </div>
            {rfqLoading ? (
              <div className="rfq-list-wrap">
                {" "}
                <RfqSkeleton />{" "}
              </div>
            ) : (
              <div className="rfq-list-wrap">
                {allRfq?.slice(0, 6).map((rfq) => (
                  <div className="rfq-list-box" key={rfq.id}>
                    <h2>{rfq.productName && Capitalize(rfq.productName)}</h2>
                    <h6>
                      {rfq.user && Capitalize(rfq.user.firstName)}{" "}
                      {rfq.user && Capitalize(rfq.user.LastName)}
                    </h6>
                    <h3>
                      {t("Quantity")}:{" "}
                      {rfq && numberWithCommas(rfq.quantityRequired)}{" "}
                      {rfq && rfq.unit}
                    </h3>
                    <p>
                      {t("Date_created")}:{" "}
                      {rfq && convertDateFormat(rfq.createdAt)}
                      <span>
                        <a
                          data-bs-toggle="modal"
                          href="#exampleModalToggle"
                          role="button"
                          onClick={(e) => {
                            viewRfqInfo(rfq.id);
                            handleEventActionRfq(rfq.productName);
                          }}
                        >
                          {t("View_details")}
                        </a>
                      </span>
                    </p>
                    <MultipleRfqModal
                      rfqInfo={rfqInfo}
                      numberWithCommas={numberWithCommas}
                      Capitalize={Capitalize}
                      convertDateFormat={convertDateFormat}
                      rfqInfoLoad={rfqInfoLoad}
                      selectedCurrency={selectedCurrency}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </section>

        {/* End of RFQ Section */}

        <section className="pt-5">
          {/* <div className="container">
            <DollsAndToys
              sectionTitle="DentalCare stores"
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="DS_DentalCare_stores"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="DS_DentalEquipment&Supplies"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="DS_SelectedDentalSupplies_MiddleEast&Africa"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="DS_CyberMondayEssentials"
            />

            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="DS_ElectronicComponents_bestsellers"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="DS_France_fastdelivery 20231123"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="DS_France_topsellers"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="DS_Germany_fastdelivery 20231123"
            />
            <DollsAndToys
              sectionTitle="Hair Removal & Tools"
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="DS_HairRemoval&Lights&Tools 10$+"
            />
            <DollsAndToys
              sectionTitle="Home & Kitchen"
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="DS_Home&Kitchen_bestsellers"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="DS_Italy_fastdelivery 20231123"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="DS_Medical Devices_limited stores"
            />
            <DollsAndToys
              sectionTitle="Mother & Kids"
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="DS_Mother&Kids 10$+"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="babyproducts_EG top seller_ 20240312"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="US_Dolls&Accessories"
            />

            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="DS_RespiratoryEquipment_stores"
            />
          </div> */}
        </section>

        {/* Banner Area */}
        <section className="banner-area">
          <div className="container">
            <div className="banner-wrap">
              <img src={HeroImg2} className="d-block w-100" alt="..." />
            </div>
          </div>
        </section>
        {/* End of Banner Area */}

        <section className="pt-5">
          {/* <div className="container">
            <DollsAndToys
              sectionTitle="Top Sellers Furniture"
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="furniture_ZA topsellers_ 20240423"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="home appliances_ZA topsellers_ 20240423"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="home_ZA topsellers_ 20240423"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="home&garden_EG top seller_ 20240312"
            />

            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="light_ZA topsellers_ 20240423"
            />
            <DollsAndToys
              sectionTitle="Motorcycle & Accessories"
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="motorcycle&accessories_ZA topsellers_ 20240423"
            />
            <DollsAndToys
              sectionTitle="Pets & Supplies"
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="pets&supplies_ZA topsellers_ 20240423"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="tool_ZA topsellers_ 20240423'"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="toys_ZA topsellers_ 20240423"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="US CN-Warehouse 20240202"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="Security_ZA topsellers_ 20240423"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="SA_Clothing&Shoes"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="Ramdan_EG top seller_ 20240312"
            />
            <DollsAndToys
              selectedCurrency={selectedCurrency}
              convertPrice={convertPrice}
              user={user}
              category="June_Bestsellers_Special Offer"
            />
          </div> */}
        </section>

        {/* New Products */}
        <section id="featured-products">
          {loading ? (
            <div className="container">
              <ProductSkeleton />
            </div>
          ) : (
            <div className="container">
              <div className="section-head">
                <h2>{t("Newly_Added_Products")}</h2>
                <div>
                  <i
                    className="fas fa-chevron-left me-4 scroll-icon"
                    onClick={() => scroll(-275)}
                  ></i>
                  <i
                    className="fas fa-chevron-right scroll-icon "
                    onClick={() => scroll(275)}
                  ></i>
                </div>
              </div>
              <div className="main-container">
                <div className="p-container">
                  <div className="product-wrap" ref={ref}>
                    {allProducts?.map((product) => (
                      <Link
                        to={`/product-info/${product.id}`}
                        key={product.id}
                        onClick={() =>
                          handleEventAction(product.productName, product.id)
                        }
                      >
                        <div className="product-card">
                          <div className="product-card-img">
                            <img
                              src={
                                product.productImages[0] &&
                                product.productImages[0].image
                              }
                              className=""
                              alt="..."
                            />
                          </div>
                          <div className="product-card-info">
                            <h3 className="seller-name">
                              {product &&
                                Capitalize(product.createdBy.firstName)}{" "}
                              {product &&
                                Capitalize(product.createdBy.LastName)}
                            </h3>
                            {product && product.productName.length > 20 ? (
                              <h2 className={"product-name"}>
                                {Capitalize(product.productName).slice(0, 20)}
                                ...
                              </h2>
                            ) : (
                              <h2 className={"product-name"}>
                                {Capitalize(product.productName)}
                              </h2>
                            )}
                            <p className="product-price">
                              <span className="p-currency">
                                {selectedCurrency}
                              </span>{" "}
                              {product.minPricePerUnit &&
                                numberWithCommas(
                                  convertCurrency(
                                    product?.minPricePerUnit,
                                    product?.currencyType,
                                    selectedCurrency
                                  )
                                )}
                              <span className="p-unit">
                                {" "}
                                / {product && product.unitForMinOrder}
                              </span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
        {/* End of New Products */}

        {/* Features */}

        <section id="features-wrap">
          <div className="container">
            <div className="section-head">
              <h2>{t("What_we_offer_on")} Traders of Africa</h2>
            </div>
            <div className="row">
              <div className="col-lg-3">
                <div className="feature-card">
                  <h2>
                    <img src={SpotIcon} className="rspot-icon" alt="..." />
                    {t("Trade_with_confidence")}
                  </h2>
                  <p>{t("first_offer")}</p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="feature-card">
                  <h2>
                    <img src={SpotIcon} className="rspot-icon" alt="..." />
                    {t("Negotiations")}
                  </h2>
                  <p>{t("second_offer")}</p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="feature-card">
                  <h2>
                    <img src={SpotIcon} className="rspot-icon" alt="..." />
                    {t("quotes")}
                  </h2>
                  <p>{t("third_offer")}</p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="feature-card">
                  <h2>
                    <img src={SpotIcon} className="rspot-icon" alt="..." />
                    {t("Diverse_African_Network")}
                  </h2>
                  <p>{t("fourth_offer")}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* End of Features */}

        <Footer />
      </>
    </>
  );
};

export default Home;
