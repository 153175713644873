import React, { useContext } from "react";
import { numberWithCommas } from "../../components/helpers/functionHelpers";
import { CurrencyExchangeContext } from "../../components/utils/CurrencyExchangeState";
import { useTranslation } from "react-i18next";

const AliexpressCartItems = ({
  cart,
  removeFromCart,
  addToCart,
  currentSelectedCurrency,
}) => {
  const { t } = useTranslation();
  const { convertCurrency } = useContext(CurrencyExchangeContext);

  const exchangeCurrency = (cost, quanity, productCurrency) => {
    const totalCost = cost * quanity;

    return convertCurrency(totalCost, productCurrency, currentSelectedCurrency);
  };

  return (
    <>
      {" "}
      {cart && cart.length > 0 && (
        <div className="col-12">
          <div className="over-card">
            <h2 className="user__title">Products from Aliexpress</h2>
            <div className="user-product-ctn">
              {cart?.map((product, index) => (
                <div className="user-product-wrap" key={index}>
                  <div className="user-product-details">
                    <div className="user-product-img">
                      <img src={product?.imgUrl} alt="product" />
                    </div>
                    {product?.shipping && (
                      <>
                        {product?.shipping !==
                          "DELIVERY NOT AVAILABLE TO YOUR ADDRESS" && (
                          <>
                            <p>{t("Estimated_delivery")}</p>
                            <p>
                              {product?.minDeliveryDuration} days -{" "}
                              {product?.maxDeliveryDuration} days
                            </p>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div className="user-product-name">
                    <div className="product-name">
                      <p>{product?.productName}</p>
                    </div>
                    <div className="product-amount">
                      <p>
                        {currentSelectedCurrency}{" "}
                        {numberWithCommas(
                          exchangeCurrency(
                            product?.cost,
                            product?.quantity,
                            product?.currency
                          )
                        )}
                      </p>

                      <div className="product-count-aliexpress">
                        <button
                          className="negative"
                          onClick={() => removeFromCart(product?.productId)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="size-6"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.25 12a.75.75 0 0 1 .75-.75h14a.75.75 0 0 1 0 1.5H5a.75.75 0 0 1-.75-.75Z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                        <h2 className="quanity">{product?.quantity}</h2>
                        <button
                          className="positive"
                          onClick={() => addToCart(product)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="size-6"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    {!product?.shipping ? (
                      <div className="product-name">
                        <p className="text-danger">
                          ENTER ADDRESS TO GET SHIPPING DETAILS
                        </p>
                      </div>
                    ) : (
                      <>
                        {product?.shipping ===
                        "DELIVERY NOT AVAILABLE TO YOUR ADDRESS" ? (
                          <div className="product-name">
                            <p className="text-danger">
                              DELIVERY FOR THIS PRODUCT NOT AVAILABLE TO YOUR
                              ADDRESS
                            </p>
                          </div>
                        ) : (
                          <>
                            {product?.freeShipping ? (
                              <div className="product-name">
                                <p className="text-success">FREE SHIPPING</p>
                              </div>
                            ) : (
                              <>
                                <div className="product-name">
                                  <p>SHIPPING FEE</p>
                                </div>
                                <div className="product-amount">
                                  <p>
                                    {currentSelectedCurrency}{" "}
                                    {product?.shippingFee &&
                                      numberWithCommas(
                                        convertCurrency(
                                          product?.shippingFee,
                                          "USD",
                                          currentSelectedCurrency
                                        )
                                      )}
                                  </p>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AliexpressCartItems;
