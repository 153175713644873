import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import { useParams, useNavigate } from "react-router-dom";
import SellersSidebar from "../SellersComponent/SellerSideBar";

import { ProtectedRoutes } from "../../../../components/utils/ProtectedRoutes";
import { GlobalContext } from "../../../../components/utils/GlobalState";

import { axiosInstance } from "../../../../components/baseUrl";

import {
  Capitalize,
  numberWithCommas,
} from "../../../../components/helpers/functionHelpers";
import { convertDateFormat } from "../../../../components/helpers/convertDate";
import cookies from "js-cookie";

import toast, { Toaster } from "react-hot-toast";
import { BigCard } from "../../../../components/SkeletonLoader/BigCard";
import { CurrencyExchangeContext } from "../../../../components/utils/CurrencyExchangeState";

const SellersOrderInfo = () => {
  const { t } = useTranslation();
  const { userLoading } = useContext(GlobalContext);
  const { convertCurrency } = useContext(CurrencyExchangeContext);

  const [orderInfo, setOrderInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const { orderId } = useParams();
  const [isActive, setIsActive] = useState(false);
  const [status, setStatus] = useState("");
  const [statusLoad, setStatusLoad] = useState(false);
  const navigate = useNavigate();
  const selectedCurrency = cookies.get("currency") || "NGN";

  const handleClick = (event) => {
    setIsActive((current) => !current);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  const updateOrder = async (orderId) => {
    let toastId = "";
    toastId = toast.loading("loading...", {
      style: {
        background: "#353434",
        color: "#fff",
      },
    });
    setStatusLoad(true);
    try {
      await axiosInstance.patch("/order", {
        status: status,
        orderID: orderId,
      });
      setStatusLoad(false);

      toast.success("Order status updated successfully", {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    } catch (error) {
      console.log(error);
      setStatusLoad(false);
      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      }
      toast.error(`${error.response.data.errors[0].message}`, {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    }
  };

  useEffect(() => {
    axiosInstance
      .get(`/order/${orderId}`)
      .then((response) => {
        setOrderInfo(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.message && error.message === "Network Error") {
          navigate("/no-connection");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const company = process.env.REACT_APP_COMPANY_NAME_SHORT;
  return (
    <>
      <Toaster />
      <div>
        <div className="grid-container seller-order-info-container">
          <div className={"menuu-icon"} onClick={handleClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <SellersSidebar isActive={isActive} handleClick={handleClick} />

          {loading ? (
            <BigCard />
          ) : userLoading ? (
            <BigCard />
          ) : (
            <main className="main sellerinfomain">
              <div className="main-overview sellerInfoOrder">
                <div className="">
                  <div className="Seller-order-info-header">
                    {orderInfo.status === "PENDING" && (
                      <button className="Seller-order-info-completed text-warning ">
                        {t("Pending")}
                      </button>
                    )}
                    {orderInfo.status === "PROCESSING" && (
                      <button className="Seller-order-info-completed text-primary ">
                        {t("Processing")}
                      </button>
                    )}
                    {orderInfo.status === "SHIPPED" && (
                      <button className="Seller-order-info-completed text-info">
                        {t("Shipped")}
                      </button>
                    )}
                    {orderInfo.status === "DELIVERED" && (
                      <button className="Seller-order-info-completed text-success">
                        {t("Delieved")}
                      </button>
                    )}
                    {orderInfo.status === "RECEIVED" && (
                      <button className="Seller-order-info-completed text-success">
                        {t("Completed")}
                      </button>
                    )}
                    {orderInfo.status === "CANCELLED" && (
                      <button className="Seller-order-info-completed text-danger">
                        {t("Cancelled")}
                      </button>
                    )}
                  </div>
                  <div className="overview-card">
                    <p>{t("Order_Info")}</p>
                    <div className="product-info">
                      <p className="product-info-title">
                        <select
                          className="form-control text-center status-order"
                          onChange={handleStatusChange}
                          name="status"
                          value={status}
                          aria-describedby="Default select example"
                          placeholder="select status"
                        >
                          <option defaultValue>{t("Select_status")}</option>
                          <option value="PENDING">{t("Pending")}</option>
                          <option value="SHIPPED">{t("Shipped")}</option>
                          <option value="DELIVERED">{t("Delivered")}</option>
                          <option value="CANCELLED">{t("Cancel")}</option>
                        </select>
                      </p>
                      <div className="product-info-title-user">
                        {statusLoad ? (
                          <button className="btn">
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </button>
                        ) : (
                          <button
                            className="btn "
                            onClick={() => updateOrder(orderInfo.id)}
                          >
                            {t("Update")}
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="product-info">
                      <p className="product-info-title">{t("Buyer")}</p>
                      <div className="product-info-title-user">
                        <p>
                          {orderInfo.User &&
                            Capitalize(orderInfo.User.firstName)}{" "}
                          {orderInfo.User &&
                            Capitalize(orderInfo.User.LastName)}
                        </p>
                      </div>
                    </div>
                    <div className="product-info">
                      <p className="product-info-title">{t("Product_Name")}</p>
                      <p>
                        {" "}
                        {orderInfo.product
                          ? Capitalize(orderInfo.product.productName)
                          : Capitalize(orderInfo.productName)}
                      </p>
                    </div>
                    <div className="product-info">
                      <p className="product-info-title">{t("Order_Number")}</p>
                      <p>{orderInfo && orderInfo.orderNumber}</p>
                    </div>
                    <div className="product-info">
                      <p className="product-info-title">{t("Total_Cost")}</p>
                      <p>
                        {selectedCurrency}{" "}
                        {orderInfo.cost &&
                          numberWithCommas(
                            convertCurrency(
                              orderInfo.cost,
                              orderInfo?.currencyType,
                              selectedCurrency
                            )
                          )}
                      </p>
                    </div>

                    <div className="product-info">
                      <p className="product-info-title">{t("Payment_Type")}</p>
                      <p>{orderInfo && orderInfo.paymentTerm}</p>
                    </div>
                    <div className="product-info">
                      <p className="product-info-title">{t("Origin")}</p>
                      <p>
                        {" "}
                        {orderInfo.product
                          ? orderInfo.product.countryOfOrigin
                          : ""}
                      </p>
                    </div>
                    <div className="product-info">
                      <p className="product-info-title">{t("Destination")}</p>
                      <p>{orderInfo && orderInfo.destination}</p>
                    </div>
                    <div className="product-info">
                      <p className="product-info-title">Incoterms</p>
                      <p>{orderInfo && orderInfo.incoterm}</p>
                    </div>

                    <div className="product-info">
                      <p className="product-info-title">{t("Notes")}</p>
                      <p>{orderInfo && orderInfo.note}</p>
                    </div>
                    <div className="product-info">
                      <p className="product-info-title">
                        {t("Order_placed_On")}
                      </p>
                      <p>
                        {orderInfo && convertDateFormat(orderInfo.createdAt)}
                      </p>
                    </div>
                  </div>
                </div>

                {orderInfo &&
                  (orderInfo.status === "CANCELLED" ? (
                    <div className="Seller-order-complete">
                      <p className="order-complete-title">
                        {t("Order_history")}
                      </p>

                      <div className="order-complete-responses">
                        <div className="response-title">
                          <i
                            className="fas fa-circle"
                            style={{ color: "#4AAE4E " }}
                          ></i>
                          <p>{t("Order_Recieved")}</p>
                        </div>
                        <div className="response-content">
                          <div className="response-border"></div>
                          <div className="respose-content-text">
                            <p>
                              <span className="text-danger">
                                {t("Your_order_has_been_cancelled.")}{" "}
                              </span>{" "}
                              {t("Reach_out_to")} {company}{" "}
                              {t("Support_to_find_out_more")}.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="Seller-order-complete">
                      <p className="order-complete-title">
                        {t("Order_history")}
                      </p>

                      <div className="order-complete-responses">
                        <div className="response-title">
                          <i className="fas fa-circle current"></i>
                          <p>{t("Order_Recieved")}</p>
                        </div>
                        <div className="response-content">
                          <div className="response-border"></div>
                          <div className="respose-content-text">
                            <p>
                              {t("Placed_Order_for")}{" "}
                              {orderInfo && orderInfo.quantityOrdered}{" "}
                              {orderInfo.product &&
                                orderInfo.product.unitForMinOrder}{" "}
                              {t("of")}{" "}
                              {orderInfo.product
                                ? Capitalize(orderInfo.product.productName)
                                : Capitalize(orderInfo.productName)}{" "}
                              {t("to_be_delivered_to")}{" "}
                              {orderInfo && orderInfo.destination}
                            </p>
                            <p className="response-content-date">
                              {orderInfo &&
                                convertDateFormat(orderInfo.createdAt)}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          orderInfo.status === "PENDING"
                            ? "order-complete-responses opacity"
                            : "order-complete-responses"
                        }
                      >
                        <div className="response-title">
                          <i
                            className={
                              orderInfo.status === "PENDING"
                                ? `${"fas fa-circle"} ${"next"}`
                                : `${"fas fa-circle"} ${"current"}`
                            }
                          ></i>
                          <p> {t("Payment_Uploaded")}</p>
                        </div>
                        <div className="response-content">
                          <div className="response-border"></div>
                          <div className="respose-content-text">
                            <p>
                              {t("Uploaded_and_processed")}
                              {orderInfo && orderInfo.paymentTerm}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          orderInfo.status === "PENDING" ||
                          orderInfo.status === "PROCESSING"
                            ? "order-complete-responses opacity"
                            : "order-complete-responses"
                        }
                      >
                        <div className="response-title">
                          <i
                            className={
                              orderInfo.status === "PENDING"
                                ? `${"fas fa-circle"} ${"wait"}`
                                : orderInfo.status === "PROCESSING"
                                ? `${"fas fa-circle"} ${"next"}`
                                : `${"fas fa-circle"} ${"current"}`
                            }
                          ></i>
                          <p>{t("Order_Shipped")}</p>
                        </div>
                        <div className="response-content">
                          <div className="response-border"></div>
                          <div className="respose-content-text">
                            <p>
                              {t("Order_for")}{" "}
                              {orderInfo && orderInfo.quantityOrdered}{" "}
                              {orderInfo.product &&
                                orderInfo.product.unitForMinOrder}{" "}
                              {t("of")}{" "}
                              {orderInfo.product
                                ? Capitalize(orderInfo.product.productName)
                                : Capitalize(orderInfo.productName)}{" "}
                              {t("has_been_shipped")}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          orderInfo.status === "PENDING" ||
                          orderInfo.status === "PROCESSING" ||
                          orderInfo.status === "SHIPPED"
                            ? "order-complete-responses opacity"
                            : "order-complete-responses"
                        }
                      >
                        <div className="response-title">
                          <i
                            className={
                              orderInfo.status === "PENDING" ||
                              orderInfo.status === "PROCESSING"
                                ? `${"fas fa-circle"} ${"wait"}`
                                : orderInfo.status === "SHIPPED"
                                ? `${"fas fa-circle"} ${"next"}`
                                : `${"fas fa-circle"} ${"current"}`
                            }
                          ></i>
                          <p>{t("Order_Delivered")}</p>
                        </div>
                        <div className="response-content">
                          <div className="response-border"></div>
                          <div className="respose-content-text">
                            <p>
                              {t("Order_for")}{" "}
                              {orderInfo && orderInfo.quantityOrdered}{" "}
                              {orderInfo.product &&
                                orderInfo.product.unitForMinOrder}{" "}
                              {t("of")}{" "}
                              {orderInfo.product
                                ? Capitalize(orderInfo.product.productName)
                                : Capitalize(orderInfo.productName)}{" "}
                              {t("has_been_delivered_in")}{" "}
                              {orderInfo && orderInfo.destination}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          orderInfo.status === "PENDING" ||
                          orderInfo.status === "PROCESSING" ||
                          orderInfo.status === "SHIPPED" ||
                          orderInfo.status === "DELIVERED"
                            ? "order-complete-responses opacity"
                            : "order-complete-responses"
                        }
                      >
                        <div className="response-title">
                          <i
                            className={
                              orderInfo.status === "PENDING" ||
                              orderInfo.status === "PROCESSING" ||
                              orderInfo.status === "SHIPPED"
                                ? `${"fas fa-circle"} ${"wait"}`
                                : orderInfo.status === "DELIVERED"
                                ? `${"fas fa-circle"} ${"next"}`
                                : `${"fas fa-circle"} ${"current"}`
                            }
                          ></i>
                          <p>
                            {orderInfo.status === "RECEIVED"
                              ? "Order Received"
                              : "Order not received"}
                          </p>
                        </div>
                        <div className="response-content">
                          <div className="response-border"></div>
                          <div className="respose-content-text">
                            <p>
                              {t("Order_for")}{" "}
                              {orderInfo && orderInfo.quantityOrdered}{" "}
                              {orderInfo.product &&
                                orderInfo.product.unitForMinOrder}{" "}
                              {t("of")}{" "}
                              {orderInfo.product
                                ? Capitalize(orderInfo.product.productName)
                                : Capitalize(orderInfo.productName)}{" "}
                              {orderInfo.status === "RECEIVED"
                                ? "has been received"
                                : "has not been received"}{" "}
                              {t("in")} {orderInfo && orderInfo.destination}{" "}
                              {t("by_buyer.")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </main>
          )}
        </div>
      </div>
    </>
  );
};

export default ProtectedRoutes(SellersOrderInfo, ["SELLER"]);
