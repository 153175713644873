import React from "react";
import { useTranslation } from "react-i18next";

const SelectPaymentModal = ({
  paymentTerm,
  handlePaymentTermChange,
  handleCurrencyChange,
  selectedCurrenci,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {" "}
      <div
        className="modal fade"
        id="exampleModal1"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {t("Update_payment_method")}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="shipping-form">
                <div className="row">
                  <div className="col-12">
                    <div className="address-ctn">
                      <label>{t("Select_payment_method")}</label>
                      <select
                        name="paymentTerm"
                        value={paymentTerm}
                        onChange={handlePaymentTermChange}
                      >
                        <option defaultValue>
                          {t("Select_payment_method")}
                        </option>
                        <option value="PAYNOW">{t("Pay_with_card")}</option>
                        <option value="MoMo">Pay with MoMo</option>
                        <option value="TRANSFER">{t("Transfer")}</option>
                        <option value="LC">{t("Letter_of_Credit")}</option>
                        <option value="TT">{t("Telegraphic_Transfer")}</option>
                      </select>
                    </div>
                    {paymentTerm === "PAYNOW" || paymentTerm === "TRANSFER" ? (
                      <div className="address-ctn">
                        <label>{t("Select_Currency")}</label>
                        <select
                          name="selectedCurrenci"
                          value={selectedCurrenci}
                          onChange={handleCurrencyChange}
                        >
                          <option value="USD">{t("Select_currency")}</option>
                          <option value="USD">USD</option>
                          <option value="NGN">NGN</option>
                          <option value="EUR">EUR</option>
                          <option value="GBP">GBP</option>
                          <option value="GHS">GHS</option>
                          <option value="XOF">XOF</option>
                        </select>
                      </div>
                    ) : null}
                  </div>
                </div>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Ok
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectPaymentModal;
