import React, { useContext, useEffect, useState } from "react";
import "../ProductDetails/ProductDetails.css";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";

import Footer from "../../components/Footer/Footer";
import HeroImg from "../../assets/img/hero-img.png";
import { axiosInstance } from "../../components/baseUrl";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ProductSkeleton from "../../components/SkeletonLoader/ProductSkeleton";
import { useGetAllCategories } from "../../components/hooks/useGetAllCategories";
import cookies from "js-cookie";
import {
  Capitalize,
  numberWithCommas,
} from "../../components/helpers/functionHelpers";
import { useTranslation } from "react-i18next";
import { useEventTag } from "../../components/hooks/useEventTag";
import { GlobalContext } from "../../components/utils/GlobalState";
import { CurrencyExchangeContext } from "../../components/utils/CurrencyExchangeState";
import NewHeader from "../../components/NewHeader/NewHeader";

const Category = () => {
  const { t } = useTranslation();
  const { user } = useContext(GlobalContext);
  const { convertCurrency } = useContext(CurrencyExchangeContext);
  const [getCategory, setGetCategory] = useState({});
  const [allProducts, setAllProducts] = useState([]);
  const [productLoad, setProductLoad] = useState(true);
  const { categoryId } = useParams();
  const [loading, setLoading] = useState(true);
  const { allCategories, cateLoading } = useGetAllCategories();
  const navigate = useNavigate();
  const selectedCurrency = cookies.get("currency") || "NGN";
  const { handleEventTag } = useEventTag();

  const ref = React.useRef(null);

  const ref1 = React.useRef(null);

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  const scroll2 = (scrollOffset) => {
    ref1.current.scrollLeft += scrollOffset;
  };

  const handleEventCategory = (categoryName) => {
    if (user) {
      handleEventTag(`c_${categoryName}`, user.id);
    } else {
      handleEventTag(`c_${categoryName}`);
    }
  };

  const handleEventAction = (productName) => {
    if (user) {
      handleEventTag(`p_${productName}`, user.id);
    } else {
      handleEventTag(`p_${productName}`);
    }
  };

  const getCategoryInfo = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        `/category/products/${categoryId}`
      );
      setGetCategory(data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error.message && error.message === "Network Error") {
        navigate("/no-connection");
      }
    }
  };

  useEffect(() => {
    if (categoryId === "all") {
      setGetCategory(allProducts);
      setLoading(false);
      setProductLoad(false);
    } else {
      getCategoryInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, allProducts]);

  const getAllProducts = async () => {
    setProductLoad(true);
    try {
      axiosInstance.get(`/product?productStatus=APPROVED`).then((response) => {
        setAllProducts(response.data.data.products);
        setProductLoad(false);
      });
    } catch (error) {
      console.log(error);
      setProductLoad(false);
      if (error.message && error.message === "Network Error") {
        navigate("/no-connection");
      }
    }
  };

  useEffect(() => {
    getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <>
      <NewHeader />
      <section id="product-listing">
        <div className="container">
          <div className="d-flex justify-content-end mb-3">
            <i
              className="fas fa-chevron-left me-4  chev-left carousel-nav"
              onClick={() => scroll(-275)}
            ></i>
            <i
              className="fas fa-chevron-right chev-right carousel-nav"
              onClick={() => scroll(275)}
            ></i>
          </div>
          {cateLoading ? (
            <ul className="product-cat-list" ref={ref}>
              {" "}
              <div className="col-lg-4 col-4">
                <p>
                  <Skeleton height={20} />
                </p>
              </div>
              <div className="col-lg-4 col-4">
                <p>
                  <Skeleton height={20} />
                </p>
              </div>
            </ul>
          ) : (
            <ul className="product-cat-list" ref={ref}>
              <NavLink
                to="/category/all"
                className={({ isActive }) => {
                  return isActive ? "active-product" : "product-cat";
                }}
                onClick={() => handleEventCategory("All categories")}
              >
                <li>All categories</li>
              </NavLink>
              {allCategories?.map((category) => (
                <NavLink
                  to={`/category/${category.id}`}
                  className={({ isActive }) => {
                    return isActive ? "active-product" : "product-cat";
                  }}
                  onClick={() => handleEventCategory(category.category)}
                  key={category.id}
                >
                  <li>
                    <span>{category.category}</span>
                  </li>
                </NavLink>
              ))}
            </ul>
          )}

          {loading || productLoad ? (
            <ProductSkeleton />
          ) : (
            <>
              <div className="section-head">
                <h2>{t("All_Products")}</h2>
                <div className="mobile-carousel-nav">
                  <i
                    className="fas fa-chevron-left me-4 carousel-nav"
                    onClick={() => scroll2(-275)}
                  ></i>
                  <i
                    className="fas fa-chevron-right carousel-nav"
                    onClick={() => scroll2(275)}
                  ></i>
                </div>
              </div>

              {getCategory.length > 0 ? (
                <div className="product-wrap" ref={ref1}>
                  {getCategory?.map((product) => (
                    <Link
                      to={`/product-info/${product.id}`}
                      key={product.id}
                      onClick={() => handleEventAction(product.productName)}
                    >
                      <div className="product-card">
                        <div className="product-card-img">
                          <img
                            src={
                              product.productImages[0] &&
                              product.productImages[0].image
                            }
                            className=""
                            alt="..."
                          />
                        </div>
                        <div className="product-card-info">
                          <h3 className="seller-name">
                            {product && Capitalize(product.createdBy.firstName)}{" "}
                            {product && Capitalize(product.createdBy.LastName)}
                          </h3>
                          <h2
                            className={
                              product.productName &&
                              product.productName.length > 20
                                ? "product-name-long"
                                : "product-name"
                            }
                          >
                            {product.productName &&
                              Capitalize(product.productName)}
                          </h2>
                          <p className="product-price">
                            <span className="p-currency">
                              {selectedCurrency}
                            </span>{" "}
                            {product.minPricePerUnit &&
                              numberWithCommas(
                                convertCurrency(
                                  product.minPricePerUnit,
                                  product?.currencyType,
                                  selectedCurrency
                                )
                              )}
                            <span className="p-unit">
                              {" "}
                              / {product && product.unitForMinOrder}
                            </span>
                          </p>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              ) : (
                <div className="empty-state">
                  <h6>{t("No_results_found")}</h6>
                  <p>{t("No_product_matched")}</p>
                </div>
              )}
            </>
          )}
        </div>
      </section>
      {/* Banner Area */}
      <section className="banner-area">
        <div className="container">
          <div className="banner-wrap">
            <img src={HeroImg} className="d-block w-100" alt="..." />
          </div>
        </div>
      </section>
      {/* End of Banner Area */}

      <Footer />
    </>
  );
};

export default Category;
